.aboutDiv {
  background-color: white;
  width: 500px;
  height: 100%;
  border-radius: 15px;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;

  max-width: 100%;
  max-height: 100%;

  padding: 25px;
}
.about-text {
  text-align: center;
  margin: auto;
}
