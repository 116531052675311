.project-collection-div {
  text-align: center;
  /* max-width: 100%; */
  margin: 25px;
}
.project-card {
  width: 600px;

  height: 500px;
  max-width: 100%;
  max-height: 100%;
  display: inline-grid;
  margin: 5px;
}
.projects-display {
  width: 1000px;
  height: 100%;
  max-width: 100%;

  margin: auto;
  background-color: white;
  text-align: center;
  /* max-height: 100%; */
  margin-top: 50px;
  margin-bottom: 50px;
  padding-bottom: 25px;
}

.project-button {
  margin-left: 5px;
  max-width: 100%;
}

.project-text {
  width: 450px;
  margin: auto;
  max-width: 100%;
}
